var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-3"},[_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.showEditPlan),callback:function ($$v) {_vm.showEditPlan=$$v},expression:"showEditPlan"}},[_c('v-card',[_c('v-card-title',{staticClass:"font-weight-bold accent--text"},[_vm._v(" "+_vm._s(_vm.openADRPlanTypes[_vm.target.planType - 1])+" ")]),_c('v-card-text',[_c('div',{staticClass:"text-right"},[_c('GroupAppliedHint')],1),_c('v-autocomplete',{staticClass:"mt-3 mx-5",attrs:{"label":"執行群組","outlined":"","dense":"","hide-details":"","background-color":"white","items":_vm.groupsForTable,"multiple":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex",staticStyle:{"width":"100%"}},[_c('div',[_vm._v(" "+_vm._s(item.value.name)+" ")]),_c('v-spacer'),_c('GroupApply',{attrs:{"group":item.value}})],1)]}}]),model:{value:(_vm.target.group),callback:function ($$v) {_vm.$set(_vm.target, "group", $$v)},expression:"target.group"}}),(_vm.target.planType === 1)?_c('v-row',{staticClass:"mt-3 mx-2"},[_c('v-col',[_c('v-select',{attrs:{"items":_vm.eventOptions,"placeholder":"選擇執行動作","hide-details":"","outlined":"","dense":""},model:{value:(_vm.target.offloadMode),callback:function ($$v) {_vm.$set(_vm.target, "offloadMode", $$v)},expression:"target.offloadMode"}})],1),(_vm.target.offloadMode === 3)?_c('v-col',[_c('v-select',{attrs:{"placeholder":"請選擇溫度","items":_vm.temperatureOption,"item-value":"acResponseValue","hide-details":"","outlined":"","dense":""},model:{value:(_vm.target.temperature),callback:function ($$v) {_vm.$set(_vm.target, "temperature", $$v)},expression:"target.temperature"}})],1):_vm._e()],1):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey","text":""},on:{"click":function($event){_vm.showEditPlan = false}}},[_vm._v(" 取消 ")]),_c('v-btn',{attrs:{"disabled":!_vm.target.group,"color":"primary","text":""},on:{"click":_vm.setOpenADRGroup}},[_vm._v(" 確認 ")])],1)],1)],1),_c('div',{staticClass:"mb-10"},[_c('div',{staticClass:"text--secondary"},[_vm._v("ADR註冊狀態")]),_c('div',{staticClass:"ma-3"},[_c('status-card',{attrs:{"school-id":_vm.tagId}})],1)]),_c('div',[_c('div',{staticClass:"text--secondary"},[_vm._v("ADR方案")]),_c('div',{staticClass:"red--text"},[_vm._v("實際執行內容將依據您與台電的合約方案")]),_c('v-card',{staticClass:"pa-2 mt-2"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.ADRPlans,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.planType",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.openADRPlanTypes[value - 1])+" ")]}},{key:"item.group",fn:function(ref){
var item = ref.item;
return [_vm._l((item.group),function(group){return _c('v-chip',{staticClass:"mr-1"},[_vm._v(_vm._s(group.name))])}),(item.planType === 1 && item.group.length)?_c('span',{staticClass:"ml-2 primary--text"},[_vm._v("( "+_vm._s(_vm.eventText(item))+" )")]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.group)?[_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.showSettings(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{staticClass:"ml-2",attrs:{"small":"","depressed":"","icon":""},on:{"click":function($event){return _vm.deleteOpenADRGroup(item.planType)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]:_c('v-btn',{attrs:{"color":"primary","small":"","depressed":""},on:{"click":function($event){_vm.target.planType = item.planType
              _vm.target.group = item.group
              _vm.showEditPlan = true}}},[_vm._v(" 新增群組 ")])]}}],null,true)})],1)],1),_c('div',{staticClass:"mt-10"},[_c('div',{staticClass:"text--secondary"},[_vm._v("不參加日期設定")]),_c('div',{staticClass:"ml-3"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"8","lg":"3","xl":"3"}},[_c('v-btn',{attrs:{"color":"primary","href":"https://service.taipower.com.tw/adr-school/Login.aspx"}},[_vm._v(" 前往設定 ")])],1)],1)],1)]),_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }